import React, {useState} from 'react';
import {useRouter} from 'next/router';
import SearchIcon from '../../../ui/svgs/SearchIcon';

export default function Search() {

    const [searchData, setSearchData] = useState('');
    const router = useRouter();
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState('');


    const onSubmitHandler = async (event) => {
        event.preventDefault();
        if(isValid) {
            router.push({
                pathname: '/search',
                query: { query: searchData.toLowerCase().trim() },
            })
            setSearchData('');
            setError('');
            setIsValid(false);
        }   
    }


    const onChangehandler = (e) => {
        let isValid = true;
        if (e.target.value.trim().length === 0) {
            isValid = false;
            setError(router.locale === "tr" ? "Bu alan boş bırakılamaz.": "This field cannot be left blank");
        }else{
            setError('');
        }
        setIsValid(isValid);
        setSearchData(e.target.value)
    }
   
    


      
    return (

            <div className="header-nav-features header-nav-features-no-border 
            header-nav-features-lg-show-border order-1 order-lg-2" 
            id="expanded-search">
                <form role="search" 
                className="search-bar"
                onSubmit={onSubmitHandler} >
                    <input
                    className="search-bar__input"
                    placeholder={router.locale === "tr" ? "Site içinde Ara" :"Search in DEF24"}
                    value={searchData} 
                    onChange = {onChangehandler}
                    type="text"/>
                    <button className="search-bar__button" 
                        disabled={searchData === ''}
                        aria-label="search"
                        type="submit">
                        <SearchIcon width={"1.6em"} className=""/>
                    </button>
                    {error && <div className="invalid-feedback"> {error} </div> }
                </form>
            </div>
     
    )
}
