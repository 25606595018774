/* eslint-disable @next/next/no-img-element */

import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { set_error_page } from '../../../redux/actions/appActions'
import { routeNames } from '../../../contents/routes/routepath';

import CaretDown from '../../svg/CaretDown';
import Link from 'next/link';
import { flags } from '../../../contents/countries/countries';
import TwitterIcon from '../../ui/svgs/TwitterIcon'
import EmailIcon from '../../ui/svgs/EmailIcon'
import PhoneIcon from '../../ui/svgs/PhoneIcon'
import cn from 'classnames';

const FeatureTopBar = (props) => {

    const { close, setCloseHandler, tel } = props;
    const router = useRouter();
    const dispatch = useDispatch();
    const stateLang = router.locale === 'tr' ? "Turkish" : "English"
    const stateErr = useSelector(state => state.app.errorPage);

    const changeLanguage = (lan) => {
        const locale = router.locale;
        if (locale === lan) {
            setCloseHandler(false);
            return
        }
        if (lan === "tr") {
            document.cookie = "language=Turkish;max-age=15552000"
        } else {
            document.cookie = "language=English;max-age=15552000"
        }
        setCloseHandler(false);
        if (router.asPath.includes('/arama'))
            return router.push(routeNames['/search'], routeNames['/search'], { locale: lan });
        if (router.asPath.includes('/search'))
            return router.push(routeNames['/arama'], routeNames['/arama'], { locale: lan });
        if (stateErr) {
            dispatch(set_error_page(false));
            return router.push('/', '/', { locale: lan });
        }
        router.push(routeNames[router.asPath], routeNames[router.asPath], { locale: lan })
    }

    return (
        <div className="header-top bg-primary" style={{ padding: ".3em 0" }}>
            <div className="container">
                <div className="header-row">
                    <div className="header-column justify-content-start">
                        <div className="header-row">
                            <nav className="header-nav-top">
                                <ul className="nav nav-pills" >
                                    <li className="nav-item dropdown nav-item-left-border 
                                        nav-item-left-border-remove 
                                        nav-item-left-border-sm-show">
                                        <button className="button-nooutline px-1 text-light text-3 d-flex align-items-center"
                                            onClick={() => setCloseHandler(!close)}
                                        >
                                            <div style={{ width: "1.6em" }}>
                                                <img
                                                    src={router.locale === "tr" ?
                                                        flags['TR'] : router.locale === "en-US" ?
                                                            flags['US'] : ""}
                                                    width="100%" height="100%"
                                                    alt="language flag" />
                                            </div>

                                            <span className="pl-1 py-0"> {router.locale === "tr" ? 'Türkçe' : 'English'}  </span>
                                            <CaretDown width={20}
                                                height={20}
                                                className={cn("caret-down", { "rotate__up": close })}
                                                color="white" />
                                        </button>
                                        <div className={cn("dropdown-menu", { "show": close })}
                                            aria-labelledby="dropdownLanguage">
                                            <button
                                                className={cn("dropdown-item d-flex align-items-end", { "active": stateLang === "Turkish" })}
                                                type="button"
                                                onClick={() => changeLanguage("tr")}>
                                                <div style={{ width: "1.6em" }}>
                                                    <img
                                                        src={flags['TR']}
                                                        width="100%"
                                                        height="100%"
                                                        alt="turkish flag"
                                                    />
                                                </div>
                                                <div className="pl-2">Türkçe</div>
                                            </button>
                                            <button
                                                className={cn("dropdown-item d-flex align-items-end", { "active": stateLang === "English" })}
                                                type="button"
                                                onClick={() => changeLanguage("en-US")}>
                                                <div style={{ width: "1.6em" }}>
                                                    <img
                                                        src={flags['US']}
                                                        alt="american flag"
                                                        width="100%"
                                                        height="100%"
                                                    />
                                                </div>
                                                <div className="pl-2"> English</div>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="header-column justify-content-end">
                        <div className="header-row">
                            <ul className="nav nav-pills" >
                                <li className="nav-item nav-item-left-border 
                                    nav-item-left-border-remove nav-item-left-border-sm-show pr-md-3">
                                    <span className="ws-nowrap py-0 px-1">
                                        <a href={`tel: ${tel}`}
                                            className="text-3 process-item-topnav">
                                            <PhoneIcon className="mb-1 mr-1" fill="white" />{tel}</a>
                                    </span>
                                </li>
                                <li className="nav-item nav-item-left-border 
                                    nav-item-left-border-remove nav-item-left-border-sm-show pr-md-3">
                                    <span className="ws-nowrap">
                                        <Link href="/contact" passHref>
                                            <a className="text-3 process-item-topnav d-flex align-items-center">
                                                <EmailIcon className="mr-1" fill="white" />
                                                info@def24.com
                                            </a>
                                        </Link>
                                    </span>
                                </li>
                            </ul>
                            <ul className="header-social-icons social-icons d-none 
                            d-sm-block social-icons-clean">
                                <li className="social-icons-twitter py-0 pl-1">
                                    <a href="http://www.twitter.com/def24tr"
                                        target="_blank" title="Twitter" rel="noopener noreferrer" >
                                        <TwitterIcon fill="white" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FeatureTopBar;