import { useState, useEffect, useRef } from 'react';
import { useRouter } from 'next/router'
import FeatureTopBar from './FeatureTopBar';

import Search from './search/Search';
import Logo from './logo/Logo';

import en from '../../../contents/header/en';
import tr from '../../../contents/header/tr';
import { useWindowSize } from '../../../hooks/useWindowSize';
import ArrowUp from '../../ui/svgs/ArrowUp';
import cn from 'classnames'
import NavPart from './NavPart';

export default function FeatureHeaderSticky({ close, setCloseHandler, setCloseHandlerNav, closeNav }) {

	const router = useRouter();
	let t = router.locale === 'tr' ? tr : en;
	const size = useWindowSize();
	const [isServiceOpen, setServiceOpen] = useState(false);
	const [isToolsOpen, setToolsOpen] = useState(false);
	const [isTop, setIsTop] = useState(0);
	const [isHoveredCap, setHoveredCap] = useState(true);

	let homePage = router.asPath === '/';
	let { width } = size;

	const header = useRef();

	useEffect(() => {
		const eventEl = () => {
			if (window.scrollY !== 0) {
				setIsTop(window.scrollY)

			} else {
				setIsTop(0)
			}
		}
		window.addEventListener('scroll', eventEl);
		return (() =>
			window.removeEventListener('scroll', eventEl))
	}, []);



	const toggleMenuHandler = () => {
		setCloseHandlerNav(!closeNav);
		setServiceOpen(false);
		setToolsOpen(false);
	}

	const onMegaMenuHandler = (id) => {
		if (id === 1 && width < 992) {
			setServiceOpen(!isServiceOpen);
			setToolsOpen(false);
		} else if (id === 2 && width < 992) {
			setServiceOpen(false);
			setToolsOpen(!isToolsOpen);
		}
	}

	const clearMegaMenuHandler = () => {
		setCloseHandlerNav(false);
		setServiceOpen(false);
		setToolsOpen(false);
		if (width > 991) {
			setHoveredCap(false);
			setTimeout(() => {
				setHoveredCap(true)
			}, 200)
		}
	}

	const scrollToTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	};

	const active = cn('mobile-menu', {
		open: closeNav,
	});

	return (
		<div className="p-relative">
			<header
				id="header-new"
				className={cn("header-effect-shrink shadow", { "header-transparent": homePage })}>
				<FeatureTopBar
					tel={t.tel}
					width={width}
					close={close}
					setCloseHandler={setCloseHandler} />
				<div
					ref={header}
					className={isTop > 0 || (width < 992 && closeNav) ?
						"header-body border-top-0 top-0 bg-quaternary p-fixed w-100" :
						"header-body border-top-0 bg-header-semi"}
					style={closeNav && (isServiceOpen || isToolsOpen) ? { height: "100vh", minHeight: "100vh" } :
						closeNav && width < 567 ? { minHeight: "100vh" } :
							closeNav && width > 567 ? { minHeight: "100vh" } :
								{ minHeight: "0" }}
				>
					<div className="header-container container" >
						<div className="header-row">
							<div className="h-100">
								<Logo isTop={isTop} width={size.width} link={true} />
							</div>
							<div className="header-column justify-content-end">
								<div className="header-row">
									<div className="header-nav header-nav-line header-nav-top-line 
								header-nav-top-line-with-border order-2 order-lg-1">
										<div className="header-nav-main header-nav-main-clone-items 
									header-nav-main-slide header-nav-main-square
									header-nav-main-effect-2 header-nav-main-sub-effect-1">
											<NavPart
												clearMegaMenuHandler={clearMegaMenuHandler}
												onMegaMenuHandler={onMegaMenuHandler}
												setCloseHandlerNav={setCloseHandlerNav}
												closeNav={closeNav}
												isServiceOpen={isServiceOpen}
												isToolsOpen={isToolsOpen}
												isHoveredCap={isHoveredCap} />
										</div>
										<div className="btn header-btn-collapse-nav navigation-wrapper">
											<div className="navigation-header">
												<span className="navigation-links">
													<button
														aria-label="Toggle Mobile Menu Button"
														className={active}
														onClick={toggleMenuHandler}
													>
														<div className="bar-one" />
														<div className="bar-two" />
														<div className="bar-three" />
													</button>
												</span>
											</div>
										</div>
										{/* <button className="btn header-btn-collapse-nav"
											onClick={toggleMenuHandler}
											aria-label="menu toggler"
										>
											{closeNav ? <CloseIcon /> : <BarsIcon />}

										</button> */}
									</div>
									<Search />
								</div>
							</div>
						</div>
					</div>
				</div>
			</header>
			<button className="scroll-to-top-button" onClick={scrollToTop}
				style={isTop > 20 ? { display: "block", opacity: ".7" } : { display: "none", opacity: "0" }}>
				<ArrowUp />
			</button>
		</div>
	)
}
