

export default function Out() {
    return (
        <svg id="fd64f68a-2e9c-4f88-917e-4ec7facdd071" data-name="Layer 1" 
        width="1em"
        height="1em"
        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 163.54 163.54">
            <g>
                <path d="M322.77,364.69H189.23a15,15,0,0,1-15-15V216.15a15,15,0,0,1,15-15h45a15,15,0,0,1,0,30h-30V334.69H307.77V304.74a15,15,0,0,1,30,0v44.95A15,15,0,0,1,322.77,364.69Z" 
                transform="translate(-174.23 -201.15)" style={{fill: "#f60"}} />
                <path d="M307.56,252.57v1.92a15,15,0,0,0,14.92,15.08h.08a15,15,0,0,0,15-14.91l.22-38.42a15,15,0,0,0-15.09-15.09l-38.41.22a15,15,0,0,0,.08,30h2L256,261.71v36.21a15,15,0,0,0,10.61-4.39Z" 
                transform="translate(-174.23 -201.15)" style={{fill: "#002345"}} />
            </g>
        </svg>
    )
}
