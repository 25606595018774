/* eslint-disable @next/next/no-img-element */
import Link from 'next/link';

export default function Logo({width, link}) {

    let widthImage = width > 3000 ? 200 : width > 2200 ? 180: width > 1900 ? 160 :
     width > 1600 ? 150 : width > 1200 ? 140 : width > 960 ? 120 : width > 768 ? 100 : 90;

    return (
        <div style={{height:"100%", display:"flex", alignItems:"center", padding:"1em 1em 1em 0em"}}>
            {link ? <Link href="/" passHref>
                <a className="h-100">
                    <img
                        src="/assets/logo/kalkan.png"
                        alt="DEF24 Logo Picture"
                        width={widthImage}
                        height="100%"
                    />
                </a>
            </Link>:
                <img
                src="/assets/logo/kalkan.png"
                alt="DEF24 Logo Picture"
                width={widthImage}
                height="100%"
                />
            }
        </div>
    )
}
