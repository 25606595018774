import Link from 'next/link';
import CaretRight from '../../../ui/svgs/CaretRight';

export default function ServiceItem({lang, name, items, onMenuClosehandler}) {

    return (
        <div className="col-lg-3">
            <span className="dropdown-mega-sub-title text-color-primary">{name}</span>
            <ul className="dropdown-mega-sub-nav">
                {items.map(item => {
                    return (
                        <li key={item.id}>
                            <Link href={`/${lang}/${item.name}`} passHref >
                                <a 
                                onClick={onMenuClosehandler}
                                className="dropdown-item text-nav-primary">
                                    <CaretRight className="mb-1" fill="caret-right"/>
                                    {item.title}</a>
                            </Link>
                        </li>
                    )
                })}
            </ul>  
        </div>
    )
}
