import * as actions from './index'

const tur = {
    City: "Şehir",
    Country: "Ülke",
    Continent: "Kıta",
    Latitude: "Enlem",
    Longitude: "Boylam",
    PostalCode: "Posta Kodu",
    Region: "Bölge",
    IsoCode:"IsoCode",
    Adress: "Adres",
    Email: "Email"
}

const eng = {
    City: "City",
    Country: "Country",
    Continent: "Continent",
    Latitude: "Latitude",
    Longitude: "Longitude",
    PostalCode: "PostalCode",
    Region: "Region",
    IsoCode:"IsoCode",
    Adress: "Adress",
    Email: "Email"
}

export const set_hostname = (data, ip) => {
    return {
        type: actions.SET_HOSTNAME,
        payload: { data:data, name: ip}
    };
};


export const set_data_reset = () => {
    return {
        type: actions.SET_DATA_RESET
    };
};

export const set_data_reset_itemone = (id) => {
    return {
        type: actions.SET_DATA_RESET_ONE,
        payload: id
    };
};

export const set_host = (data) => {
    return {
        type: actions.SET_HOST,
        payload: data
    };
};





export const set_ip_details = (dataNew, hostName, ip, lang, tab) => {
   
    let data = [];
    let t = lang === "Turkish" ? tur : eng;
    for (let item in dataNew) {
        data.push({ name: t[item], value: dataNew[item] })
    }
    data.unshift({ name: "IP", value: ip})
    data.push({name:"TabName", value: tab})
    if(hostName !== '') data.push({name:"HostName", value: hostName})

    // if(tab === "DbIp" || tab === "IpInfo"){
    //     return {
    //         type: actions.SET_IP_DETAILS_OTHER,
    //         payload: { data, name: ip, db: tab}
    //     };
    // }

    return {
        type: actions.SET_IP_DETAILS,
        payload: { data, name: ip, db: tab}
    };
};


export const set_ip_details_otherdb = (dataNew, hostName, ip, lang, tab) => {
    
    let data = [];
    let t = lang === "Turkish" ? tur : eng;
    for (let item in dataNew) {
        data.push({name: t[item], value: dataNew[item]})
    }
    data.unshift({ name: "IP", value: ip })
    data.push({ name:"TabName", value: tab })
    if(hostName !== '') data.push({name:"HostName", value: hostName})
    return {
        type: actions.SET_IP_DETAILS_OTHER,
        payload: { data, name: ip, db: tab}
    };
};

export const set_entities = (ip, data) => {
    // let fn = [];
    // let adr = [];
    // let email= [];
    // let tel = [];
    // let abuseEmail = [];

    // data.map(item => {
    //     if(item.fn) fn.push(item.fn)
    //     if(item.adr) adr.push(item.adr);
    //     if(item.adr_work) adr.push(item.adr_work);
    //     if(item.email) email.push(item.email);
    //     if(item.email_email) email.push(item.email_email);
    //     if(item.email_work) email.push(item.email_work);
    //     if(item.abuse_email) abuseEmail.push(item.abuse_email);
    //     if(item.tel_work_voice) tel.push(item.tel_work_voice);
    //     if(item.tel_voice) tel.push(item.tel_voice);
    // }) 
    // let dataUpdate = {fn:fn[0], adres:adr[0], email:email[0], phone:tel[0], abuse:abuseEmail[0]}

    console.log("data" ,data)
    return {
        type: actions.SET_IP_PROVIDER_DATA,
        payload: { data:data, name: ip}
    };
}
export const set_entities_reset = () => {
    return {
        type: actions.SET_IP_PROVIDER_DATA_RESET_ALL
    };
};

export const set_entities_reset_itemone = (id) => {
    return {
        type: actions.SET_IP_PROVIDER_DATA_RESET_ONE,
        payload: id
    };
};

export const set_ip = (ip, headers, agent) => {
    return {
        type: actions.SET_IP,
        payload: { ip: ip, headers: headers, userAgent: agent }
    };
};

export const set_country = (country) => {
    return {
        type: actions.SET_COUNTRY,
        payload: country
    };
}


export const set_init_mode = (item) => {
    return {
        type: actions.SET_INIT_MODE,
        payload: item
    };
}



export const set_password_initval = (data) => {
    return {
        type: actions.SET_PASSWORD_VALUES,
        payload: data
    };
}

export const set_passphrase_initval = (data) => {
    return {
        type: actions.SET_PASSPHRASE_VALUES,
        payload: data
    };
}



export const set_tabnames = (ip, data) => {
    return {
        type: actions.SET_TABNAMES,
        payload: {ip, data}
    };
};

export const set_tabnames_reset = () => {
    return {
        type: actions.SET_TABNAMES_DELETE,
    };
};

export const set_tabnames_reset_one = (id) => {
    return {
        type: actions.SET_TABNAMES_DELETEONE,
        payload: id
    };
};

export const set_error_page = (pos) => {
    return {
        type: actions.SET_ERROR_PAGE,
        payload: pos
    };
};

export const set_modal = (pos) => {
    return {
        type: actions.SET_MODAL,
        payload: pos
    };
};