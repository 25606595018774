// eslint-disable-next-line import/no-anonymous-default-export
export default {
    home: "HOME",
    corporate: "COMPANY",
    about: "About Us",
    routeAbout: "/company",
    routeContact: "/contact",
    routeSearch: "/search",
    human: "Human Resources",
    services: "SERVICES",
    tools: "TOOLS",
    contact: "CONTACT",
    tel: "+1-305-5150578",
    adress: "Def24 Inc, 815 Ponce De Leon Blvd, Coral Gables, FL 33134, USA",
    dataServiceHeader: [
        {
            name: "Cyber Security", id: "topitem02",
            items: [
                { id: "item01penetrasyon", title: "Penetration Test", name: "cyber/penetration-test" },
                { id: "item03olgunluk", title: "OSINT", name: "cyber/osint" },
                { id: "item04tarama", title: "Audit", name: "cyber/audit" },
            ]
        },
        {
            name: "IT Security", id: "topitem03",
            items: [
                { id: "item01ortamguv", title: "Data Security", name: "it/data-security" },
                { id: "item02oadlibi", title: "Reverse Engineering", name: "it/reverse-engineering" },
                { id: "item03verimlilik", title: "Efficiency Analysis", name: "it/efficiency-analysis" },
            ]
        },
    ],
    dataServiceHeaderTscm: [
        // {
        //     name: "TSCM", id: "topitem05",
        //     items: [
        //         { id: "item01kvkk", title: "Technical Surveillance", name: "tscm" }
        //     ]
        // },
        {
            name: "Trainee", id: "topitem01",
            items: [
                { id: "item01training", title: "Trainees", name: "training" },
                // { id: "item02training", title: "Training Record", name: "training/record" },
            ]
        }
    ],
    dataServiceHeaderDanis: [
        {
            name: "Consultancy", id: "topitem04",
            items: [
                { id: "item02iso", title: "ISO 27001", name: "consultancy/iso27001" },
                { id: "item03mailguv", title: "Domain", name: "consultancy/domain" },
                { id: "item04mimar", title: "Cyber", name: "consultancy/cyber" },
            ]
        }
    ],
    dataToolsHeader: [
        {
            name: "Location Tools", id: "topitem01tools",
            items: [
                { id: "item03OtherTools", title: "IP8.com", name: "https://ip8.com" },
                { id: "item01geoip", title: "IP Location", name: "geoip" },
                { id: "item02geoip", title: "Reverse Hosting", name: "reverse-hosting" },
            ]
        },
        {
            name: "Security Tools", id: "topitem02tools",
            items: [
                { id: "item04OtherTools", title: "Mail1.io", name: "https://mail1.io" },
                { id: "sifrematik", title: "Password Generator", name: "password-generator" },
                { id: "parola", title: "Passphrase Generator", name: "passphrase-generator" },
            ]
        },
        {
            name: "Research Tools", id: "topitem04tools",
            items: [
                { id: "item01OtherTools", title: "Cyberchef.org", name: "https://cyberchef.org" },
                { id: "item04OtherTools", title: "Dnsleaktest.org", name: "https://dnsleaktest.org" },
            ]
        }],
    dataServicesFooter: [
        { name: "Cyber Security", link: "/services/cyber/penetration-test" },
        { name: "IT Security", link: "/services/it/data-security" },
        { name: "Trainee", link: "/services/training" },
        { name: "Consultancy", link: "/services/consultancy/cyber" }
    ],
    dataToolsFooter: [
        { name: "Password Gen.", link: "/tools/password-generator" },
        { name: "Passphrase Gen.", link: "/tools/passphrase-generator" },
        { name: "IP Location", link: "/tools/geoip" },
        { name: "Reverse Hosting", link: "/tools/reverse-hosting" },
        { name: "Mail1", link:"https://mail1.io", target:true},
        { name: "CyberChef", link: "https://cyberchef.org/", target: true },
        { name: "DNSLeakTest", link: "https://dnsleaktest.org/", target: true },
        { name: "IP8", link: "https://ip8.com/", target: true },

    ],
    nameDefence: "IT and Defense Company",
    copyRightTitle: "All Rights Reserved.",
    blogDatas: ["Article"],
    termsConditionsName: "Terms and Conditions",
    termsLink: "/terms-conditions",
    privacyName: "Privacy Policy",
    privacyLink: "/privacy",
    servicesFooter: "Services",
    toolsFooter: "Tools",
    contactFooter: "Contact",
}