/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import FeatureHeaderSticky from './navigation/header/FeatureHeaderSticky';
import Footer from './navigation/footer/Footer';
import { useDispatch, useSelector } from 'react-redux';
import {
    set_ip_details,
    set_ip, set_entities,
    set_tabnames, set_country, set_hostname,
} from '../redux/actions/appActions'
import Backdrop from './common/modal/Backdrop';
import axios from 'axios'

const api = process.env.isApiserver

const Layout = ({ children, router }) => {

    const [close, setClose] = useState(false);
    const [closeNav, setCloseNav] = useState(false);
    const dispatch = useDispatch();

    let { locale, asPath } = router;

    const isModal = useSelector(state => state.app.isModalPos)
    const selectUser = useSelector(state => state.app)

    const setCloseHandler = (pos) => {
        setClose(pos);
    }

    const setCloseHandlerNav = (pos) => {
        setCloseNav(pos);
    }

    const onCloseHandler = () => {
        setClose(false);
        setCloseNav(false);
    }

    const fetchIpLocation = async () => {
        let lang = locale === "tr" ? "Turkish" : "English";
        let res;
        
        // if (!selectUser.ip) {
        try {
            res = await axios.get(`${api}/ip`)
            if (res.data.retcode !== 200) return;
            dispatch(set_ip(res.data.ip, res.data.headers, res.data.userAgent));
        } catch (err) {
            
        }
        if(!res?.data?.ip) return
        dispatch(set_tabnames(res?.data?.ip, "mm"))
        if (selectUser.ip !== res?.data?.ip) {
            try {
                const detailJson = await axios.post(`${api}/iplocation/p1`, { host: res?.data?.ip });
                if (detailJson.data.retcode !== 200) return
                dispatch(set_ip_details(detailJson.data.data, res.data.ip, detailJson.data.ip4, lang, "mm"));
                dispatch(set_country(detailJson.data.data.Country))
            } catch (err2) {
                console.log(err2)
            }
            try {
                const respDns = await axios.post(`${api}/rdns`, { host: res.data.ip });
                if (respDns?.retcode === 200) dispatch(set_hostname(respDns?.data?.dns?.[0], res.data.ip))
            } catch (err) {
                console.log(err);
            }


           
            try {
                if(res?.data?.ip) {
                    const resp = await axios.post(`https://api2.ip8.com/rdap/lookup`, { ip: [res?.data?.ip] });
                    if (resp?.data?.retcode === 201) {
                        const data = resp?.data?.result?.[res?.data?.ip];
                        dispatch(set_entities(res.data.ip, data));
                    }
                }
               
            } catch (err) {

            }

    

            



        }

        // } 
        //  else if (selectUser.location !== undefined) {
        //     dispatch(set_tabnames(selectUser.ip, "mm"))
        //     // try {
        //     //     const detailJson = await axios.post(`${process.env.isApiserver}/iplocation/p1`, { host: selectUser.ip });
        //     //     if (detailJson.data.retcode !== 200) return
        //     //     dispatch(set_tabnames(res.data.ip, "mm"))
        //     //     dispatch(set_ip_details(detailJson.data.data, res.data.ip, detailJson.data.ip4, lang, "mm"));
        //     //     dispatch(set_country(detailJson.data.data.Country))
        //     // } catch (err2) {
        //     //     console.log(err2)
        //     // }
        //     dispatch(set_ip_details(selectUser.location, selectUser.ip, selectUser.ip, lang, "mm"));
        //     if (selectUser.location) dispatch(set_country(selectUser.location.Country))
        //     try {
        //         const respDns = await axios.post(`${process.env.isApiserver}/rdns`, { host: selectUser.ip });
        //         dispatch(set_hostname(respDns.data.dns[0], selectUser.ip))
        //         const resp = await axios.get(`https://api.ip8.com/rdap/lookup/${selectUser.ip}`);
        //         const newData = [];
        //         if (resp.data.data) {
        //             resp.data.data.entities.map(item => {
        //                 if (item.details) {
        //                     newData.push(item.details);
        //                     if (newData.length > 0) newData[0].name = resp.data.data.name;
        //                     if (newData.length > 0) newData[0].country = resp.data.data.country;
        //                 }
        //             })
        //         }
        //         dispatch(set_entities(selectUser.ip, newData));
        //     } catch (err) {
        //         console.log(err);

        //     }
        // }
    }

    useEffect(() => {
        fetchIpLocation()
    }, [asPath])

    return (
        <div className="position-relative">
            <Backdrop pos={isModal} />
            <FeatureHeaderSticky
                close={close}
                setCloseHandler={setCloseHandler}
                closeNav={closeNav}
                setCloseHandlerNav={setCloseHandlerNav}
            />
            <div id="main">
                <div onClick={onCloseHandler}>
                    {children}
                </div>
                <Footer />
            </div>
        </div>)

}



export default Layout;