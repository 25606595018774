import FooterMiddleRight from './FooterMiddleRight'
import FooterMiddleItem from './FooterMiddleItem'
import FooterLeftItem from './FooterLeftItem'
import en from '../../../contents/header/en';
import tr from '../../../contents/header/tr';
import { useRouter } from 'next/router';
import Logo from '../header/logo/Logo';
import Link from 'next/link'
import { enRouteNames, trRouteNames } from '../../../contents/routes/routepath'
import { useState, useEffect } from 'react'

export default function Footer() {

	const router = useRouter();
	const { asPath, locale } = router
	const [routeTr, setRouteTr] = useState(trRouteNames[`${asPath}`]);
	const [route, setRoute] = useState(enRouteNames[`${asPath}`]);

	let t = locale === 'tr' ? tr : en;

	useEffect(() => {
		setRouteTr(trRouteNames[`${asPath}`])
		setRoute(enRouteNames[`${asPath}`])
	}, [asPath])

	const changeLang = (lan) => {
		if (locale === lan) {
			return
		}
		if (lan === "tr") {
			document.cookie = "language=Turkish;max-age=15552000"
		} else {
			document.cookie = "language=English;max-age=15552000"
		}
	}

	return (
		<footer id="footer" className="bg-color-quaternary border-top-0 mt-0">
			<div className="container">
				<div className="row justify-content-between pt-lg-5 py-3">
					<FooterMiddleItem
						title={t.servicesFooter}
						cyber={t.dataServicesFooter}
					/>
					<FooterMiddleRight
						title={t.toolsFooter}
						cyber={t.dataToolsFooter}
					/>
					<FooterLeftItem
						title={t.contactFooter}
						adress={t.adress}
						phone={t.tel}
					/>
					<div className="col-lg-5 col-md-12 d-flex"
						style={{ flexDirection: "column", alignItems: "center" }}>
						<div className="d-none d-md-block">
							<Logo isTop={0}
								width={1800}
								link={false} />
						</div>

						<div className="footer-copyright bg-color-quaternary border-top-0">
							<div className="row no-gutters">
								<div className="col d-flex flex-column">
									<div className="w-100 line-height-2 text-center text-3 text-color-primary ">
										© Copyright 2021-{new Date().getFullYear()}  DEF24 Inc. {t.nameDefence}. </div>
									<div className="line-height-2 text-center text-3 text-color-primary">
										{t.copyRightTitle}  </div>

									<div className="row justify-content-center align-items-center">
										<Link href={t.termsLink} passHref>
											<a className="text-3 process-item line-height-2 text-center pr-1">
												{t.termsConditionsName}
											</a>
										</Link>
										&
										<Link href={t.privacyLink} passHref>
											<a className="text-3 process-item line-height-2 text-center pl-1">
												{t.privacyName}
											</a>
										</Link>

									</div>
									<div className="row justify-content-center align-items-center">
										<Link
											href={locale === 'tr' ? asPath : route ? `${route}` : '/'}
											passHref
											locale="tr"
										>
											<a
												onClick={() => changeLang('tr')}
												className={locale === "tr" ? "px-1 lang-active" : "px-1"}><span>  TR  </span></a>
										</Link>|
										<Link

											href={locale === 'en-US' ? asPath : routeTr ? routeTr : '/'}
											passHref
											locale="en-US">
											<a
												onClick={() => changeLang('en-US')}
												className={locale !== "tr" ? "px-1 lang-active" : "px-1"}><span>  EN  </span></a>
										</Link>
										{/* <a className="px-1" href={router.locale === 'tr' ? routeTr : `/${asPath}`}><span>  EN </span></a> */}
									</div>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}
