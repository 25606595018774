import Link from 'next/link'
import CaretRight from '../../ui/svgs/CaretRight'

export default function FooterMiddleItem(props) {
    return (
        <div className="col-lg-2 col-md-3 col-6 mb-4 mb-lg-0">
            <h2 className="font-weight-semibold text-color-primary text-5-6 mb-2 line-height-3"
                style={{ textTransform: "capitalize" }}>{props.title}</h2>
            <ul className="d-flex list list-unstyled mb-0"
                style={{ flexDirection: "column" }}>
                {props.cyber.map(item =>
                    <Link key={item.name} href={item.link} >
                        <a className="text-4-5 line-height-6 text-nav-primary">
                            <CaretRight className="mb-1 caret-right"/>
                            {item.name}</a>
                    </Link>
                )}
            </ul>
        </div>
    )
}
