export const flags = {
    "AD": "/flags/ad.png",
    "AE": "/flags/ae.png",
    "AF": "/flags/af.png",
    "AG": "/flags/ag.png",
    "AI": "/flags/ai.png",
    "AL": "/flags/al.png",
    "AM": "/flags/am.png",
    "AO": "/flags/ao.png",
    "AQ": "/flags/aq.png",
    "AR": "/flags/ar.png",
    "AS": "/flags/as.png",
    "AT": "/flags/at.png",
    "AU": "/flags/au.png",
    "AW": "/flags/aw.png",
    "AX": "/flags/ax.png",
    "AZ": "/flags/az.png",
    "BA": "/flags/ba.png",
    "BB": "/flags/bb.png",
    "BD": "/flags/bd.png",
    "BE": "/flags/be.png",
    "BF": "/flags/bf.png",
    "BG": "/flags/bg.png",
    "BH": "/flags/bh.png",
    "BI": "/flags/bi.png",
    "BJ": "/flags/bj.png",
    "BL": "/flags/bl.png",
    "BM": "/flags/bm.png",
    "BN": "/flags/bn.png",
    "BO": "/flags/bo.png",
    "BQ": "/flags/bq.png",
    "BR": "/flags/br.png",
    "BS": "/flags/bs.png",
    "BT": "/flags/bt.png",
    "BV": "/flags/bv.png",
    "BW": "/flags/bw.png",
    "BY": "/flags/by.png",
    "BZ": "/flags/bz.png",
    "CA": "/flags/ca.png",
    "CC": "/flags/cc.png",
    "CD": "/flags/cd.png",
    "CF": "/flags/cf.png",
    "CG": "/flags/cg.png",
    "CH": "/flags/ch.png",
    "CI": "/flags/ci.png",
    "CK": "/flags/ck.png",
    "CL": "/flags/cl.png",
    "CM": "/flags/cm.png",
    "CN": "/flags/cn.png",
    "CO": "/flags/co.png",
    "CR": "/flags/cr.png",
    "CU": "/flags/cu.png",
    "CV": "/flags/cv.png",
    "CW": "/flags/cw.png",
    "CX": "/flags/cx.png",
    "CY": "/flags/cy.png",
    "CZ": "/flags/cz.png",
    "DE": "/flags/de.png",
    "DJ": "/flags/dj.png",
    "DK": "/flags/dk.png",
    "DM": "/flags/dm.png",
    "DO": "/flags/do.png",
    "DZ": "/flags/dz.png",
    "EC": "/flags/ec.png",
    "EE": "/flags/ee.png",
    "EG": "/flags/eg.png",
    "EH": "/flags/eh.png",
    "ER": "/flags/er.png",
    "ES": "/flags/es.png",
    "ET": "/flags/et.png",
    "EU": "/flags/eu.png",
    "FI": "/flags/fi.png",
    "FJ": "/flags/fj.png",
    "FK": "/flags/fk.png",
    "FM": "/flags/fm.png",
    "FO": "/flags/fo.png",
    "FR": "/flags/fr.png",
    "GA": "/flags/ga.png",
    "GB-ENG": "/flags/fi.png",
    "GB-NIR": "/flags/fi.png",
    "GB-SCT": "/flags/fi.png",
    "GB-WLS": "/flags/fi.png",
    "GB": "/flags/gb.png",
    "GD": "/flags/gd.png",
    "GE": "/flags/ge.png",
    "GF": "/flags/gf.png",
    "GG": "/flags/gg.png",
    "GH": "/flags/gh.png",
    "GI": "/flags/gi.png",
    "GL": "/flags/gl.png",
    "GM": "/flags/gm.png",
    "GN": "/flags/gn.png",
    "GP": "/flags/gp.png",
    "GQ": "/flags/gq.png",
    "GR": "/flags/gr.png",
    "GS": "/flags/gs.png",
    "GT": "/flags/gt.png",
    "GU": "/flags/gu.png",
    "GW": "/flags/gw.png",
    "GY": "/flags/gy.png",
    "HK": "/flags/hk.png",
    "HM": "/flags/hm.png",
    "HN": "/flags/hn.png",
    "HR": "/flags/hr.png",
    "HT": "/flags/ht.png",
    "HU": "/flags/hu.png",
    "ID": "/flags/ıd.png",
    "IE": "/flags/ie.png",
    "IL": "/flags/il.png",
    "IM": "/flags/im.png",
    "IN": "/flags/in.png",
    "IO": "/flags/io.png",
    "IQ": "/flags/iq.png",
    "IR": "/flags/ir.png",
    "IS": "/flags/is.png",
    "IT": "/flags/it.png",
    "JE": "/flags/je.png",
    "JM": "/flags/jm.png",
    "JO": "/flags/jo.png",
    "JP": "/flags/jp.png",
    "KE": "/flags/ke.png",
    "KG": "/flags/kg.png",
    "KH": "/flags/kh.png",
    "KI": "/flags/ki.png",
    "KM": "/flags/km.png",
    "KN": "/flags/kn.png",
    "KP": "/flags/kp.png",
    "KR": "/flags/kr.png",
    "KW": "/flags/kw.png",
    "KY": "/flags/ky.png",
    "KZ": "/flags/kz.png",
    "LA": "/flags/la.png",
    "LB": "/flags/lb.png",
    "LC": "/flags/lc.png",
    "LI": "/flags/li.png",
    "LK": "/flags/lk.png",
    "LR": "/flags/lr.png",
    "LS": "/flags/ls.png",
    "LT": "/flags/lt.png",
    "LU": "/flags/lu.png",
    "LV": "/flags/lv.png",
    "LY": "/flags/ly.png",
    "MA": "/flags/lu.png",
    "MC": "/flags/ma.png",
    "MD": "/flags/md.png",
    "ME": "/flags/me.png",
    "MF": "/flags/mf.png",
    "MG": "/flags/mg.png",
    "MH": "/flags/mh.png",
    "MK": "/flags/mk.png",
    "ML": "/flags/ml.png",
    "MM": "/flags/mm.png",
    "MN": "/flags/mn.png",
    "MO": "/flags/mo.png",
    "MP": "/flags/mp.png",
    "MQ": "/flags/mq.png",
    "MR": "/flags/mr.png",
    "MS": "/flags/ms.png",
    "MT": "/flags/mt.png",
    "MU": "/flags/mu.png",
    "MV": "/flags/mv.png",
    "MW": "/flags/mw.png",
    "MX": "/flags/mx.png",
    "MY": "/flags/my.png",
    "MZ": "/flags/mz.png",
    "NA": "/flags/na.png",
    "NC": "/flags/nc.png",
    "NE": "/flags/ne.png",
    "NF": "/flags/nf.png",
    "NG": "/flags/ng.png",
    "NI": "/flags/ni.png",
    "NL": "/flags/nl.png",
    "NO": "/flags/no.png",
    "NP": "/flags/np.png",
    "NR": "/flags/nr.png",
    "NU": "/flags/nu.png",
    "NZ": "/flags/nz.png",
    "OM": "/flags/om.png",
    "PA": "/flags/pa.png",
    "PE": "/flags/pe.png",
    "PF": "/flags/pf.png",
    "PG": "/flags/pg.png",
    "PH": "/flags/ph.png",
    "PK": "/flags/pk.png",
    "PL": "/flags/pl.png",
    "PM": "/flags/pm.png",
    "PN": "/flags/pn.png",
    "PR": "/flags/pr.png",
    "PS": "/flags/ps.png",
    "PT": "/flags/pt.png",
    "PW": "/flags/pw.png",
    "PY": "/flags/py.png",
    "QA": "/flags/qa.png",
    "RE": "/flags/re.png",
    "RO": "/flags/ro.png",
    "RS": "/flags/rs.png",
    "RU": "/flags/ru.png",
    "RW": "/flags/rw.png",
    "SA": "/flags/sa.png",
    "SB": "/flags/sb.png",
    "SC": "/flags/sc.png",
    "SD": "/flags/sd.png",
    "SE": "/flags/se.png",
    "SG": "/flags/sg.png",
    "SH": "/flags/sh.png",
    "SI": "/flags/si.png",
    "SJ": "/flags/sj.png",
    "SK": "/flags/sk.png",
    "SL": "/flags/sl.png",
    "SM": "/flags/sm.png",
    "SN": "/flags/sn.png",
    "SO": "/flags/so.png",
    "SR": "/flags/sr.png",
    "SS": "/flags/ss.png",
    "ST": "/flags/st.png",
    "SV": "/flags/sv.png",
    "SX": "/flags/sx.png",
    "SY": "/flags/sy.png",
    "SZ": "/flags/sz.png",
    "TC": "/flags/tc.png",
    "TD": "/flags/td.png",
    "TF": "/flags/tf.png",
    "TG": "/flags/tg.png",
    "TH": "/flags/th.png",
    "TJ": "/flags/tj.png",
    "TK": "/flags/tk.png",
    "TL": "/flags/tl.png",
    "TM": "/flags/tm.png",
    "TN": "/flags/tn.png",
    "TO": "/flags/to.png",
    "TR": "/flags/tr.png",
    "TT": "/flags/tt.png",
    "TV": "/flags/tv.png",
    "TW": "/flags/tw.png",
    "TZ": "/flags/tz.png",
    "UA": "/flags/ua.png",
    "UG": "/flags/ug.png",
    "UM": "/flags/um.png",
    "US": "/flags/us.png",
    "UY": "/flags/uy.png",
    "UZ": "/flags/uz.png",
    "VA": "/flags/va.png",
    "VC": "/flags/vc.png",
    "VE": "/flags/ve.png",
    "VG": "/flags/vg.png",
    "VI": "/flags/vi.png",
    "VN": "/flags/vn.png",
    "VU": "/flags/vu.png",
    "WF": "/flags/wf.png",
    "WS": "/flags/ws.png",
    "XK": "/flags/xk.png",
    "YE": "/flags/ye.png",
    "YT": "/flags/yt.png",
    "ZA": "/flags/za.png",
    "ZM": "/flags/zm.png",
    "ZW": "/flags/zw.png"
}



