/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import Layout from '../components/Layout';
import { Provider } from 'react-redux'
import store from '../redux/store';
import '../styles/global.scss';
import { createWrapper } from 'next-redux-wrapper';
import { useDispatch } from 'react-redux';

// import NProgress from "nprogress"
import { useRouter } from "next/router"
import { enRouteNames, trRouteNames } from '../contents/routes/routepath'
import { set_host } from '../redux/actions/appActions';


function MyApp({ Component, pageProps, headers, ip, host }) {

  const router = useRouter();
  const { asPath, locale } = router;
  const dispatch = useDispatch()

  const getData = () => {
    if (host && host !== undefined) dispatch(set_host(host))
    if (headers && headers.language === "Turkish" && locale === "en-US") {
      const route = trRouteNames[`${asPath}`]
      if (route) router.push(enRouteNames[route], enRouteNames[route], { locale: 'tr' });
      else router.push(asPath, asPath, { locale: 'tr' });
    }
    if (headers && headers.language === "English" && locale === "tr") {
      const route = enRouteNames[`${asPath}`]
      if (route) router.push(trRouteNames[route], trRouteNames[route], { locale: 'en-US' });
      else router.push(asPath, asPath, { locale: 'en-US' });
    }

    if (locale === "en-US" && !enRouteNames.hasOwnProperty(asPath)) {
      const route = trRouteNames[`${asPath}`]
      if (route) router.push(enRouteNames[route], enRouteNames[route], { locale: 'tr' });
    }
    if (locale === "tr" && !trRouteNames.hasOwnProperty(asPath)) {
      const route = enRouteNames[`${asPath}`]
      if (route) router.push(trRouteNames[route], trRouteNames[route], { locale: 'en-US' })
    }

  }

  useEffect(() => {
    getData()
  }, []);


  return (
    <Provider store={store}>
      <Layout router={router}>
        <Component {...pageProps} />
      </Layout>
    </Provider>)
}


MyApp.getInitialProps = async (ctx) => {
  const { req } = ctx.ctx
  try {
    if (req) {
      return {
        headers: {
          connection: req.headers.connection ? req.headers.connection : "",
          "user-agent": req.headers['user-agent'] ? req.headers['user-agent'] : "",
          "accept-encoding": req.headers['accept-encoding'] ? req.headers['accept-encoding'] : "",
          "accept-language": req.headers['accept-language'] ? req.headers['accept-language'] : "",
          language: req.cookies['language'] ? req.cookies['language'] : "",
        },
        ip: req.headers['x-tha-ip'] ? req.headers['x-tha-ip'] : "",
        host: req.headers['host'] ? req.headers['host'] : "",
      }
    } else {
      return {
      }
    }

  } catch (err) {
    return {
    }
  }
}

const makeStore = () => store;
const wrapper = createWrapper(makeStore);
//withRedux wrapper that passes the store to the App Component
export default wrapper.withRedux(MyApp);






