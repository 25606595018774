import React from 'react'
import { useRouter } from 'next/router'
import ActiveLink from './activeLink/ActiveLink';
import TscmEdu from './serviceItems/TscmEdu';
import ServiceItem from './serviceItems/ServiceItem';
import ToolItem from './serviceItems/ToolItem';
import CaretDown from '../../svg/CaretDown';
import Wrapper from './Wrapper';
import cn from 'classnames'
import en from '../../../contents/header/en';
import tr from '../../../contents/header/tr';

export default function NavPart({ setCloseHandlerNav, onMegaMenuHandler, clearMegaMenuHandler, closeNav, isServiceOpen, isToolsOpen, isHoveredCap }) {
    const router = useRouter();
    let t = router.locale === 'tr' ? tr : en;


    return (
        <nav className={cn("collapse", { "show": closeNav })}>
            <ul className="nav nav-pills" id="mainNav">
                <li className="dropdown">
                    <Wrapper link="/" title={t.home}
                        setCloseHandlerNav={setCloseHandlerNav} />
                    <ActiveLink
                        passHref={true}
                        href="/"
                        activeClassName="active">
                        <a
                            onClick={() => setCloseHandlerNav(false)}
                            className="dropdown-item item-original">{t.home}</a>
                    </ActiveLink>
                </li>
                <li className="dropdown"  >
                    <Wrapper link={t.routeAbout}
                        title={t.corporate}
                        setCloseHandlerNav={setCloseHandlerNav} />
                    <ActiveLink
                        passHref={true}
                        activeClassName="active"
                        href={t.routeAbout} >
                        <a
                            href={t.routeAbout}
                            onClick={() => setCloseHandlerNav(false)}
                            className={
                                router.asPath === '/about' ||
                                    router.asPath === '/hakkimizda' ?
                                    "dropdown-item item-original active" :
                                    "dropdown-item item-original"}
                        >  {t.corporate}
                        </a>
                    </ActiveLink>
                </li>
                <li className={isServiceOpen ? "dropdown dropdown-mega open" :
                    isHoveredCap ? "dropdown dropdown-mega" : ""}
                >
                    <Wrapper title={t.services} className={
                        router.asPath.includes('/services') ||
                            router.asPath.includes('/hizmetler') ?
                            "dropdown-item dropdown-toggle active" :
                            "dropdown-item dropdown-toggle"
                    }
                        classNameTwo={
                            router.asPath.includes('/services') ||
                                router.asPath.includes('/hizmetler') ?
                                "dropdown-item dropdown-toggle item-two active" :
                                "dropdown-item dropdown-toggle item-two"
                        }
                    />
                    <a
                        role="button"
                        onClick={() => onMegaMenuHandler(1)}
                        className={router.asPath.includes('/services') ||
                            router.asPath.includes('/hizmetler') ?
                            "dropdown-item dropdown-toggle item-original active item-mega-menu" :
                            "dropdown-item dropdown-toggle item-original item-mega-menu"}
                    >
                        <span className="pr-1">{t.services}</span>
                        <CaretDown width={20} height={20}
                            style={router.asPath.includes('/services') ||
                                router.asPath.includes('/hizmetler') ? { color: "#ff6600" } : { color: "inherit" }}
                            className={isServiceOpen ? "caret-down rotate__up" : "caret-down"} />
                    </a>

                    <ul className="dropdown-menu">
                        <li>
                            <div className="dropdown-mega-content">
                                <div className="row">
                                    {t.dataServiceHeader.map(item => {
                                        return (
                                            <ServiceItem
                                                lang={t === en ? "services" : "hizmetler"}
                                                key={item.id}
                                                name={item.name}
                                                items={item.items}
                                                onMenuClosehandler={clearMegaMenuHandler}
                                            />)
                                    })}
                                    {t.dataServiceHeaderDanis.map(item => {
                                        return (
                                            <ServiceItem
                                                lang={t === en ? "services" : "hizmetler"}
                                                key={item.id}
                                                name={item.name}
                                                items={item.items}
                                                onMenuClosehandler={clearMegaMenuHandler} />)
                                    })}
                                    <div className="col-lg-3">
                                        {t.dataServiceHeaderTscm.map(item => {
                                            return (
                                                <TscmEdu
                                                    lang={t === en ? "services" : "hizmetler"}
                                                    key={item.id}
                                                    name={item.name}
                                                    items={item.items}
                                                    onMenuClosehandler={clearMegaMenuHandler} />)
                                        })}
                                    </div>

                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li className={cn("", { "dropdown dropdown-mega open": isToolsOpen }, { "dropdown dropdown-mega": isHoveredCap })}  >
                    <Wrapper title={t.tools} className={
                        router.asPath.includes('/tools') || router.asPath.includes('/araclar') ?
                            "dropdown-item dropdown-toggle active" : "dropdown-item dropdown-toggle"
                    }
                        classNameTwo={
                            router.asPath.includes('/tools') || router.asPath.includes('/araclar') ?
                                "dropdown-item dropdown-toggle item-two active" : "dropdown-item dropdown-toggle item-two"
                        }
                    />
                    <a
                        role="button"
                        onClick={() => onMegaMenuHandler(2)}
                        className={router.asPath.includes('/tools') || router.asPath.includes('/araclar') ?
                            "dropdown-item dropdown-toggle active item-original item-mega-menu" :
                            "dropdown-item dropdown-toggle item-original item-mega-menu"}
                    >

                        <span className="pr-1">{t.tools}</span>
                        <CaretDown width={20} height={20}
                            className={isToolsOpen ? "caret-down rotate__up" : "caret-down"} />

                    </a>
                    <ul className="dropdown-menu">
                        <li>
                            <div className="dropdown-mega-content">
                                <div className="row">
                                    {t.dataToolsHeader.map(item =>
                                        <ToolItem
                                            lang={t === en ? "tools" : "araclar"}
                                            name={item.name}
                                            key={item.id}
                                            onMenuClosehandler={clearMegaMenuHandler}
                                            items={item.items ? item.items : []} />)}
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li className="dropdown">
                    <Wrapper link={t.routeContact} title={t.contact} setCloseHandlerNav={setCloseHandlerNav} />
                    <ActiveLink
                        activeClassName="active"
                        href={t.routeContact} passHref={true}>
                        <a className="dropdown-item item-original"
                            onClick={() => setCloseHandlerNav(false)}
                            href={t.routeContact}>
                            {t.contact}
                        </a>
                    </ActiveLink>
                </li>
            </ul>
        </nav>
    )
}
