import Link from 'next/link';
import Out from '../../../svg/Out';
import CaretRight from '../../../ui/svgs/CaretRight';


// One common component in toolbar header 


const ToolItem = ({ lang, name, items, onMenuClosehandler }) => {

    return (
        <div className="col-lg-4">
            <span className="dropdown-mega-sub-title text-color-primary" >{name}</span>
            <ul className="dropdown-mega-sub-nav">
                {items.map(item => {
                    if (item.id === "item01OtherTools" || item.id === "item02OtherTools" || item.id === "item03OtherTools" || item.id === "item04OtherTools") {
                        return (
                            <li key={item.id}>
                                <Link
                                    href={{
                                        pathname: `${item.name}`,
                                    }} passHref>
                                    <a 
                                    target="_blank"
                                    
                                        rel="noopener"
                                        onClick={onMenuClosehandler}
                                        className="d-flex align-items-center dropdown-item text-nav-primary">
                                        <span style={{padding:"0 .3em .1em 0"}} > <Out /> </span>
                                        <span > {item.title} </span>      
                                    </a>
                                </Link>
                            </li>
                        )
                    }
                    return (
                        <li key={item.id}>
                            <Link href={`/${lang}/${item.name}`} passHref >
                                <a 
                                    onClick={onMenuClosehandler}
                                    className="dropdown-item text-nav-primary">
                                        <CaretRight className="mb-1" fill="caret-right"/>
                                        {item.title}</a>
                            </Link>
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default ToolItem;