import Link from 'next/link';
import CaretRight from '../../ui/svgs/CaretRight'

export default function FooterMiddleRightItem(props) {
    return (
        <div className="col-lg-2 col-md-4 col-6 mb-4 mb-lg-0">
            <div className="row">
                <div className="col-12">
                <h2 className="font-weight-semibold text-color-primary text-5-6 mb-2 line-height-3" 
                >{props.title}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <ul className="d-flex flex-column list list-unstyled mb-0 ">
                        {props.cyber.map(item => 
                        <Link key={item.name} href={item.link} >
                            <a target={item.target && "_blank" } 
                            className="text-4-5 line-height-6 text-nav-primary" rel={item.target && "noopener"}>
                                <CaretRight className="mb-1 caret-right"/>
                                {item.name}</a>
                        </Link>
                        )}  
                    </ul>
                </div>       
            </div>
        </div>
    )
}

