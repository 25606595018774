import ActiveLink from './activeLink/ActiveLink';

export default function Wrapper({ title, setCloseHandlerNav, link, className, classNameTwo }) {
    return (
        <span className="wrapper-items-cloned">
            {link ?
                <>
                    <ActiveLink
                        href={link}
                        activeClassName="active">
                        <a
                            onClick={() => setCloseHandlerNav(false)}
                            className="dropdown-item">{title}</a>
                    </ActiveLink>
                    <ActiveLink
                        href={link}
                        activeClassName="active">
                        <a onClick={() => setCloseHandlerNav(false)}
                            className="dropdown-item item-two">{title}</a>
                    </ActiveLink>
                </>
                : <>
                    <a className={className} href={link} style={{cursor:"pointer"}}>{title}</a>
                    <a className={classNameTwo} href={link} style={{cursor:"pointer"}}>{title}</a>
                </>
            }
        </span>
    )
}
