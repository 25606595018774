import Link from 'next/link';
import TwitterIcon from '../../ui/svgs/TwitterIcon'
import EmailIcon from '../../ui/svgs/EmailIcon'
import PhoneIcon from '../../ui/svgs/PhoneIcon'

export default function FooterLeftItem(props) {
    return (
        <div className="col-lg-3 col-md-3 col-6 mb-4 mb-lg-0">
            <h2 className="font-weight-semibold text-color-primary text-5-6 mb-2 line-height-3">{props.title} </h2>
            <span className="d-block text-3 line-height-6 process-item">
                <a href={`tel:${props.phone}`} className="text-nav-primary">
                    <PhoneIcon className="mr-1 mb-2 caret-right"/>
                    <span className="text-4-5">{props.phone} </span> </a>
            </span>
            <span className="d-block line-height-6 process-item">
                <Link href="/contact" passHref>
                    <a className="d-flex align-items-center text-nav-primary">
                        <EmailIcon className="mr-1 caret-right"/>
                        <span className="text-4-5"> info@def24.com </span> 
                    </a>
                </Link>
            </span>
            <span className="d-block line-height-6 social-icons social-icons-transparent">
                <span className="social-icons-twitter ">
                    <Link href="https://www.twitter.com/def24tr" passHref>
                        <a target="_blank" title="Twitter" className="text-nav-primary" rel="noopener" >
                            <TwitterIcon className="mb-1 caret-right"/>
                            <span className="text-4-5"> @def24tr </span>
                        </a>
                    </Link>
                </span>
            </span>
        </div>
    )
}
