// eslint-disable-next-line import/no-anonymous-default-export
export default {
    home:"ANASAYFA",
    corporate:"KURUMSAL",
    about:"Hakkımızda",
    routeAbout:"/kurumsal",
    routeContact:"/iletisim",
    routeSearch:"/arama",
    human:"İnsan Kaynakları",
    services:"HİZMETLER",
    tools:"ARAÇLAR",
    contact:"İLETİŞİM",
    tel:"+1-305-5150578",
    adress:"Çaybaşı Mah. 1358 Sok. Premier Plaza 1/A Blok, 07100 Muratpaşa/Antalya",
    dataServiceHeader: [
        {
            name: "Siber Güvenlik", id: "topitem02",
            items: [
                { id: "item01penetrasyon", title: "Sızma Testleri", name: "siber/sizma-testi" },
                { id: "item03olgunluk", title: "Açık Kaynak Bilgi İsth.", name: "siber/osint" },
                { id: "item04tarama", title: "Zafiyet Tarama ve Ynt.", name: "siber/audit" },
            ]
        },
        {
            name: "Bilişim Güvenliği", id: "topitem03",
            items: [
                { id: "item01ortamguv", title: "Veri Güvenliği", name: "bilisim/veri-guvenligi" },
                { id: "item02oadlibi", title: "Tersine Mühendislik", name: "bilisim/tersine-muhendislik" },
                { id: "item03verimlilik", title: "Verimlilik Analizi", name: "bilisim/verimlilik-analizi" },
            ]
        },
    ],
    dataServiceHeaderTscm: [
        // {
        //     name: "TSCM", id: "topitem05",
        //     items: [
        //         { id: "item01kvkk", title: "Ortam Güvenliği", name: "ortam-guvenligi" }
        //     ]
        // },
        {
            name: "Eğitim", id: "topitem01",
            items: [
                { id: "item01egitim", title: "Eğitimler", name: "egitim" },
                // { id: "item02egitim", title: "Eğitim Kayıt", name: "egitim/kayit" },
            ]
        }
    ],
    dataServiceHeaderDanis:[
        {
            name: "Danışmanlık", id: "topitem04",
            items: [
                { id: "item01kvkk", title: "KVKK", name: 'danismanlik/kvkk' },
                { id: "item02iso", title: "ISO 27001", name: "danismanlik/iso27001" },
                { id: "item03mailguv", title: "Domain", name: "danismanlik/domain" },
                { id: "item04mimar", title: "Siber Güvenlik", name: "danismanlik/siber" },
            ]
        }
    ],
    dataToolsHeader : [
        {name: "Lokasyon Araçları", id:"topitem01tools", 
            items: [
                {id:"item03OtherTools", title:"IP8.com", name:"https://ip8.com"},
                {id:"item01geoip", title:"IP Lokasyon", name:"geoip"},
                {id:"item02geoip", title:"Ters DNS Kaydı", name:"ters-dns"},
            ]},
        {name: "Güvenlik Araçları", id:"topitem02tools",  
            items: [
                {id:"item04OtherTools", title:"Mail1.io", name:"https://mail1.io"},
                {id:"item02OtherTools", title:"Şifre.net", name:"https://şifre.net"},
                {id:"sifrematik", title:"Şifre Oluşturucu", name:"sifre-olusturucu"},
                {id:"parola", title:"Parola Oluşturucu", name:"parola-olusturucu"},
            ]},
        {name: "Araştırma Araçları", id:"topitem04tools",
            items: [
                {id:"item01OtherTools", title:"Cyberchef.org", name:"https://cyberchef.org"},
                {id:"item04OtherTools", title:"Dnsleaktest.org", name:"https://dnsleaktest.org"},
                
        ]}
    ],
    dataServicesFooter:[
        {name:"Siber Güvenlik", link:"/hizmetler/siber/sizma-testi"},
        {name:"Bilişim Güvenliği", link:"/hizmetler/bilisim/veri-guvenligi"},
        {name:"Eğitim", link:"/hizmetler/egitim"},
        {name:"Danışmanlık", link:"/hizmetler/danismanlik/siber"}
    ],
    dataToolsFooter:[
        {name:"Şifre Oluşturucu", link:"/araclar/sifre-olusturucu"},
        {name:"Parola Oluşturucu", link:"/araclar/parola-olusturucu"},
        {name:"IP Lokasyon", link:"/araclar/geoip"},
        {name:"Ters DNS Kaydı", link:"/araclar/ters-dns"},
        {name:"Mail1", link:"https://mail1.io", target:true},
        {name:"CyberChef", link:"https://cyberchef.org", target:true},
        {name:"IP8", link:"https://ip8.com/", target:true},
        {name:"DNSLeakTest", link:"https://dnsleaktest.org/", target:true},
        {name:"ŞifreNet", link:"https://sifre.net/", target:true},
    ],
    nameDefence:"Bilişim ve Savunma",
    copyRightTitle:"Her Hakkı Saklıdır.",
    blogDatas:["Makale"],
    termsConditionsName:"Kullanım Koşulları",
    termsLink:"/kullanim-kosullari",
    privacyName:"Gizlilik Poliçesi",
    privacyLink:"/gizlilik",
    servicesFooter:"Hizmetler",
    toolsFooter:"Araçlar",
    contactFooter:"İletişim"


}
