import * as actions from '../actions';


export const initialState = {
  hostName: {},
  errorPage: false,
  ip: "",
  country: "",
  host: "",
  isModalPos: false,
  headers: {},
  userAgent: {},
  data: {},
  entities: {},
  tabNames: {},
  modestatus: "password",
  password: {
    length: 16,
    upper: true,
    lower: true,
    numbers: true,
    symbols: false
  },
  passphrase: {
    length: 5,
    delimiter: 'hyphen'
  }
}


const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_IP_DETAILS:
      const newDataIp = { [action.payload.name]: { [action.payload.db]: action.payload.data }, ...state.data }
      return {
        ...state,
        data: newDataIp
      }
    case actions.SET_IP_DETAILS_OTHER:
      const eleman = state.data[action.payload.name];
      const newEl = { ...eleman, [action.payload.db]: action.payload.data }
      return {
        ...state,
        data: { ...state.data, [action.payload.name]: newEl }
      }
    case actions.SET_IP_PROVIDER_DATA:
      return {
        ...state,
        entities: { ...state.entities, [action.payload.name]: action.payload.data },
      }
    case actions.SET_IP_PROVIDER_DATA_RESET_ALL:
      return {
        ...state,
        entities: {},
        hostName: {}
      }
    case actions.SET_IP_PROVIDER_DATA_RESET_ONE:
      const providerData = state.entities;
      const hostnameData = state.hostName;
      delete providerData[action.payload];
      delete hostnameData[action.payload];
      const updateEntities = Object.assign({}, providerData);
      const updateHostname = Object.assign({}, hostnameData);
      return {
        ...state,
        entities: { ...updateEntities },
        hostName: { ...updateHostname }
      }

    case actions.SET_IP:
      return {
        ...state,
        ip: action.payload.ip,
        headers: { ...state.headers, ...action.payload.headers },
        userAgent: { ...state.userAgent, ...action.payload.userAgent }
      }
    case actions.SET_COUNTRY:
      return {
        ...state,
        country: action.payload
      }
    case actions.SET_DATA_RESET:
      return {
        ...state,
        data: {}
      }
    case actions.SET_DATA_RESET_ONE:
      const deletedData = state.data;
      delete deletedData[action.payload];
      const dataDeleted = Object.assign({}, deletedData);
      return {
        ...state,
        data: { ...dataDeleted },
      }
    case actions.SET_PASSWORD_VALUES:
      return {
        ...state,
        password: {
          ...state.password, [action.payload.name]: action.payload.value
        }
      }
    case actions.SET_PASSPHRASE_VALUES:
      return {
        ...state,
        passphrase: {
          ...state.passphrase, [action.payload.name]: action.payload.value
        }
      }
    case actions.SET_INIT_MODE:
      return {
        ...state,
        modestatus: action.payload
      }
    case actions.SET_TABNAMES:
      return {
        ...state,
        tabNames: { ...state.tabNames, [action.payload.ip]: action.payload.data }
      }
    case actions.SET_TABNAMES_DELETE:
      return {
        ...state,
        tabNames: {}
      }
    case actions.SET_MODAL:
      return {
        ...state,
        isModalPos: action.payload
      }
    case actions.SET_TABNAMES_DELETEONE:
      const deletedDataTab = state.tabNames;
      delete deletedDataTab[action.payload];
      const dataTabsUpd = Object.assign({}, deletedDataTab);
      return {
        ...state,
        tabNames: { ...dataTabsUpd },
      }
    case actions.SET_ERROR_PAGE:
      return {
        ...state,
        errorPage: action.payload
      }
    case actions.SET_HOSTNAME:
      return {
        ...state,
        hostName: { ...state.hostName, [action.payload.name]: action.payload.data }
      }
    case actions.SET_HOST:
      return {
        ...state,
        host: action.payload
      }
    default:
      return state
  }
}

export default appReducer;
