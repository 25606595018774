
export const SET_IP_DETAILS = "SET_IP_DETAILS";
export const SET_IP = "SET_IP";
export const SET_DATA_RESET = "SET_DATA_RESET";
export const SET_DATA_RESET_ONE = "SET_DATA_RESET_ONE";
export const SET_PASSWORD_VALUES = "SET_PASSWORD_VALUES";
export const SET_PASSPHRASE_VALUES = "SET_PASSPHRASE_VALUES";
export const SET_INIT_MODE = "SET_INIT_MODE";
export const SET_IP_DETAILS_OTHER = "SET_IP_DETAILS_OTHER";

export const SET_IP_PROVIDER_DATA = "SET_IP_PROVIDER_DATA"
export const SET_IP_PROVIDER_DATA_RESET_ALL = "SET_IP_PROVIDER_DATA_RESET_ALL"
export const SET_IP_PROVIDER_DATA_RESET_ONE = "SET_IP_PROVIDER_DATA_RESET_ONE"

export const SET_TABNAMES = "SET_TABNAMES"
export const SET_TABNAMES_DELETE = "SET_TABNAMES_DELETE"
export const SET_TABNAMES_DELETEONE = "SET_TABNAMES_DELETEONE"
export const SET_ERROR_PAGE= "SET_ERROR_PAGE"
export const SET_MODAL = "SET_MODAL"
export const SET_COUNTRY = "SET_COUNTRY"
export const SET_HOSTNAME = "SET_HOSTNAME"
export const SET_HOST ="SET_HOST"