export const routeNames = {
    "/":"/",
    "/kurumsal":"/company",
    "/company":"/kurumsal",
    "/contact":"/iletisim",
    "/iletisim":"/contact",
    "/search":"/arama",
    "/arama":"/search",
    "/terms-conditions":"/kullanim-kosullari",
    "/kullanim-kosullari":"/terms-conditions",
    "/privacy":"/gizlilik",
    "/gizlilik":"/privacy",
    /// services cyber
    "/services/cyber/penetration-test":"/hizmetler/siber/sizma-testi",
    "/hizmetler/siber/sizma-testi":"/services/cyber/penetration-test",
    "/services/cyber/osint":"/hizmetler/siber/osint",
    "/hizmetler/siber/osint":"/services/cyber/osint",
    "/services/cyber/audit":"/hizmetler/siber/audit",
    "/hizmetler/siber/audit":"/services/cyber/audit",
    /// services it
    "/services/it/data-security":"/hizmetler/bilisim/veri-guvenligi",
    "/hizmetler/bilisim/veri-guvenligi":"/services/it/data-security",
    "/services/it/reverse-engineering":"/hizmetler/bilisim/tersine-muhendislik",
    "/hizmetler/bilisim/tersine-muhendislik":"/services/it/reverse-engineering",
    "/services/it/efficiency-analysis":"/hizmetler/bilisim/verimlilik-analizi",
    "/hizmetler/bilisim/verimlilik-analizi":"/services/it/efficiency-analysis",
    /// service tscm
    "/services/tscm":"/hizmetler/ortam-guvenligi",
    "/hizmetler/ortam-guvenligi":"/services/tscm",
    /// services trainee
    "/services/training":"/hizmetler/egitim",
    "/hizmetler/egitim":"/services/training",
    "/services/training/record":"/hizmetler/egitim/kayit",
    "/hizmetler/egitim/kayit":"/services/training/record",
    /// services consult
    "/services/consultancy/gdpr":"/hizmetler/danismanlik/kvkk",
    "/hizmetler/danismanlik/kvkk":"/services/consultancy/gdpr",
    "/services/consultancy/iso27001":"/hizmetler/danismanlik/iso27001",
    "/hizmetler/danismanlik/iso27001":"/services/consultancy/iso27001",
    "/services/consultancy/domain":"/hizmetler/danismanlik/domain",
    "/hizmetler/danismanlik/domain":"/services/consultancy/domain",
    "/services/consultancy/cyber":"/hizmetler/danismanlik/siber",
    "/hizmetler/danismanlik/siber":"/services/consultancy/cyber",
    // tools
    "/tools/passphrase-generator":"/araclar/parola-olusturucu",
    "/araclar/parola-olusturucu":"/tools/passphrase-generator",
    "/tools/password-generator":"/araclar/sifre-olusturucu",
    "/araclar/sifre-olusturucu":"/tools/password-generator",
    "/tools/geoip":"/araclar/geoip",
    "/araclar/geoip":"/tools/geoip",
    "/tools/reverse-hosting":"/araclar/ters-dns",
    "/araclar/ters-dns":"/tools/reverse-hosting",

}


export const enRouteNames = {
    "/":"/",
    "/company":"/kurumsal",
    "/contact":"/iletisim",
    "/search":"/arama",
    "/terms-conditions":"/kullanim-kosullari",
    "/privacy":"/gizlilik",
    /// services cyber
    "/services/cyber/penetration-test":"/hizmetler/siber/sizma-testi",
    "/services/cyber/osint":"/hizmetler/siber/osint",
    "/services/cyber/audit":"/hizmetler/siber/audit",
    /// services it
    "/services/it/data-security":"/hizmetler/bilisim/veri-guvenligi",
    "/services/it/reverse-engineering":"/hizmetler/bilisim/tersine-muhendislik",
    "/services/it/efficiency-analysis":"/hizmetler/bilisim/verimlilik-analizi",
    /// service tscm
    "/services/tscm":"/hizmetler/ortam-guvenligi",
    /// services trainee
    "/services/training":"/hizmetler/egitim",
    "/services/training/record":"/hizmetler/egitim/kayit",
    /// services consult
    "/services/consultancy/gdpr":"/hizmetler/danismanlik/kvkk",
    "/services/consultancy/iso27001":"/hizmetler/danismanlik/iso27001",
    "/services/consultancy/domain":"/hizmetler/danismanlik/domain",
    "/services/consultancy/cyber":"/hizmetler/danismanlik/siber",
    // tools
    "/tools/passphrase-generator":"/araclar/parola-olusturucu",
    "/tools/password-generator":"/araclar/sifre-olusturucu",
    "/tools/geoip":"/araclar/geoip",
    "/tools/reverse-hosting":"/araclar/ters-dns",

}

export const trRouteNames = {
    "/":"/",
    "/kurumsal":"/company",
    "/iletisim":"/contact",
    "/arama":"/search",
    "/kullanim-kosullari":"/terms-conditions",
    "/gizlilik":"/privacy",
    /// services cyber
    "/hizmetler/siber/sizma-testi":"/services/cyber/penetration-test",
    "/hizmetler/siber/osint":"/services/cyber/osint",
    "/hizmetler/siber/audit":"/services/cyber/audit",
    /// services it
    "/hizmetler/bilisim/veri-guvenligi":"/services/it/data-security",
    "/hizmetler/bilisim/tersine-muhendislik":"/services/it/reverse-engineering",
    "/hizmetler/bilisim/verimlilik-analizi":"/services/it/efficiency-analysis",
    /// service tscm
    "/hizmetler/ortam-guvenligi":"/services/tscm",
    /// services trainee
    "/hizmetler/egitim":"/services/training",
    "/hizmetler/egitim/kayit":"/services/training/record",
    /// services consult
    "/hizmetler/danismanlik/kvkk":"/services/consultancy/gdpr",
    "/hizmetler/danismanlik/iso27001":"/services/consultancy/iso27001",
    "/hizmetler/danismanlik/domain":"/services/consultancy/domain",
    "/hizmetler/danismanlik/siber":"/services/consultancy/cyber",
    // tools
    "/araclar/parola-olusturucu":"/tools/passphrase-generator",
    "/araclar/sifre-olusturucu":"/tools/password-generator",
    "/araclar/geoip":"/tools/geoip",
    "/araclar/ters-dns":"/tools/reverse-hosting",

}